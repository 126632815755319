import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import OpenTime from "./opentime"
import Img from "gatsby-image"

const Test = props => {
  const [search, setSearch] = useState("")
  const [category, setCategory] = useState("")
  const [ownloaction, setOwnLocation] = useState({
    latitude: 0,
    longitude: 0,
  })

  useEffect(() => {
    const ltd = localStorage.getItem("latitude")
    const lngd = localStorage.getItem("longitude")

    if (ltd) {
      setOwnLocation({
        latitude: ltd,
        longitude: lngd,
      })
    } else {
      setOwnLocation({
        latitude: 0,
        longitude: 0,
      })
    }
  }, [])

  var i
  const arr1 = props.data

  for (i = 0; i < arr1.length; i++) {
    //  var sum = arr1[i].test1 + 1
    const toRadian = angle => (Math.PI / 180) * angle
    const distance = (a, b) => (Math.PI / 180) * (a - b)
    const RADIUS_OF_EARTH_IN_KM = 6371

    let lat1 = arr1[i].node.data.store_coordinates.latitude
    let lat2 = ownloaction.latitude
    const lon1 = arr1[i].node.data.store_coordinates.longitude
    const lon2 = ownloaction.longitude

    const dLat = distance(lat2, lat1)
    const dLon = distance(lon2, lon1)

    lat1 = toRadian(lat1)
    lat2 = toRadian(lat2)

    // Haversine Formula
    const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2)
    const c = 2 * Math.asin(Math.sqrt(a))

    let finalDistance = RADIUS_OF_EARTH_IN_KM * c

    let fd = Number(finalDistance).toFixed(2)
    let numbertostring = fd.toString()
    var obj = arr1[i].node
    Object.assign(obj, { store_distance: fd })
  }

  const changeFilter = category => {
    setCategory(category)
  }

  const distancesort = arr1.sort((a, b) =>
    parseFloat(a.node.store_distance) > parseFloat(b.node.store_distance) ? 1 : -1
  )

  let filtered = distancesort.filter(edge => {
    if (search === "" && category !== "") {
      return edge.node.data.store_categorie === category
    } else if (search !== "" && category === "") {
      return edge.node.data.store_title.text.toLowerCase().indexOf(search.toLowerCase()) !== -1
    } else if (search !== "" && category !== "") {
      return (
        edge.node.data.store_title.text.toLowerCase().indexOf(search.toLowerCase()) !== -1 &&
        edge.node.data.store_categorie === category
      )
    }
  })
  let filtered1 = props.data.filter(edge => {
    if (search === "" && category !== "") {
      return edge.node.data.store_categorie === category
    } else if (search !== "" && category === "") {
      return edge.node.data.store_title.text.toLowerCase().indexOf(search.toLowerCase()) !== -1
    } else if (search !== "" && category !== "") {
      return (
        edge.node.data.store_title.text.toLowerCase().indexOf(search.toLowerCase()) !== -1 &&
        edge.node.data.store_categorie === category
      )
    }
  })

  const updateSearch = e => {
    e.preventDefault()
    setSearch(e.target.value)
  }

  const enterPress = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      const element = document.getElementById("ip")
      element.blur()
    }
  }

  return (
    <div id="results">
      <div className="searchbar-holder">
        <div className="sb-holder">
          <input
            id="ip"
            placeholder="Suche dein Lieblingsgeschäft..."
            className="search-bar"
            type="text"
            value={search}
            onChange={updateSearch}
            onKeyPress={e => enterPress(e)}
          />
          {search !== "" ? (
            <p className="sb-holder__x" onClick={() => setSearch("")}>
              X
            </p>
          ) : null}
        </div>

        <button className={category === "" ? "active-category" : "class"} onClick={e => changeFilter("")}>
          Alle
        </button>
        <button
          className={category === "Restaurant" ? "active-category" : "class"}
          onClick={e => changeFilter("Restaurant")}
        >
          Restaurant
        </button>
        <button className={category === "Cafe" ? "active-category" : "class"} onClick={e => changeFilter("Cafe")}>
          Cafe
        </button>
        <button
          className={category === "Bäckerei" ? "active-category" : "class"}
          onClick={e => changeFilter("Bäckerei")}
        >
          Bäckerei
        </button>

        <button
          className={category === "Einzelhandel" ? "active-category" : "class"}
          onClick={e => changeFilter("Einzelhandel")}
        >
          Einzelhandel
        </button>

        <div>
          {search !== "" || category !== "" ? (
            <p className="store-length">{filtered.length} Geschäfte</p>
          ) : (
              <p className="store-length">{props.data.length} Geschäfte</p>
            )}
        </div>
        <p className="openingtime-hint">Öffnungszeiten können abweichen.</p>
      </div>
      <div>
        {ownloaction.longitude !== 0 ? (
          <div>
            {search !== "" || category !== "" ? (
              <div className="grid-x grid-margin-x">
                {filtered.map(store => {
                  return (
                    <div className="large-6 cell" key={store.node.uid}>
                      <Link to={store.node.uid} className="storelist-store" >
                        <div className="storelist-store__logo-holder">
                          <Img
                            className="storelist-store__logo"
                            fluid={store.node.data.store_logo.localFile.childImageSharp.fluid}
                          ></Img>
                        </div>
                        <div className="storelist-store__content">
                          <h4> {store.node.data.store_title.text} </h4>
                          <p className="storelist-store__categorie">
                            {" "}
                            <i>
                              {store.node.data.store_categorie}, {store.node.data.store_service}
                            </i>{" "}
                          </p>
                          <p> {store.node.data.store_desicription.text.substring(0, 55)}... </p>
                          <div className="storelist-store__lower">
                            <p className="distance"> {store.node.store_distance} km</p>
                            <OpenTime times={store.node.data.store_openingtime} show="short" />
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            ) : (
                <div className="grid-x grid-margin-x">
                  {distancesort.map(store => {
                    return (
                      <div className="large-6 cell" key={store.node.uid}>
                        <Link to={store.node.uid} className="storelist-store" >
                          <div className="storelist-store__logo-holder">
                            <Img
                              className="storelist-store__logo"
                              fluid={store.node.data.store_logo.localFile.childImageSharp.fluid}
                            ></Img>
                          </div>
                          <div className="storelist-store__content">
                            <h4> {store.node.data.store_title.text} </h4>
                            <p className="storelist-store__categorie">
                              {" "}
                              <i>
                                {store.node.data.store_categorie}, {store.node.data.store_service}
                              </i>{" "}
                            </p>
                            <p> {store.node.data.store_desicription.text.substring(0, 55)}... </p>
                            <div className="storelist-store__lower">
                              <p className="distance"> {store.node.store_distance} km</p>
                              <OpenTime times={store.node.data.store_openingtime} show="short" />
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              )}
          </div>
        ) : (
            <div>
              {search !== "" || category !== "" ? (
                <div className="grid-x grid-margin-x">
                  {filtered1.map(store => {
                    return (
                      <div className="large-6 cell" key={store.node.uid}>
                        <Link to={store.node.uid} className="storelist-store" >
                          <div className="storelist-store__logo-holder">
                            <Img
                              className="storelist-store__logo"
                              fluid={store.node.data.store_logo.localFile.childImageSharp.fluid}
                            ></Img>
                          </div>
                          <div className="storelist-store__content">
                            <h4> {store.node.data.store_title.text} </h4>
                            <p className="storelist-store__categorie">
                              {" "}
                              <i>
                                {store.node.data.store_categorie}, {store.node.data.store_service}
                              </i>{" "}
                            </p>
                            <p> {store.node.data.store_desicription.text.substring(0, 55)}... </p>
                            <div className="storelist-store__lower">
                              <OpenTime times={store.node.data.store_openingtime} show="short" />
                            </div>
                          </div>
                        </Link>
                      </div>

                    )
                  })}
                </div>
              ) : (
                  <div className="grid-x grid-margin-x">
                    {props.data.map(store => {
                      return (
                        <div className="large-6 cell" key={store.node.uid}>
                          <Link to={store.node.uid} className="storelist-store" >
                            <div className="storelist-store__logo-holder">
                              <Img
                                className="storelist-store__logo"
                                fluid={store.node.data.store_logo.localFile.childImageSharp.fluid}
                              ></Img>
                            </div>
                            <div className="storelist-store__content">
                              <h4> {store.node.data.store_title.text} </h4>
                              <p className="storelist-store__categorie">
                                {" "}
                                <i>
                                  {store.node.data.store_categorie}, {store.node.data.store_service}
                                </i>{" "}
                              </p>
                              <p> {store.node.data.store_desicription.text.substring(0, 55)}... </p>
                              <div className="storelist-store__lower">
                                <OpenTime times={store.node.data.store_openingtime} show="short" />
                              </div>
                            </div>
                          </Link>
                        </div>

                      )
                    })}
                  </div>
                )}
            </div>
          )}
      </div>
    </div>
  )
}
export default Test

import React from "react"
import Nav from "../components/nav"
import Footer from "../components/footer"
import Test from "../components/test"

const Impressum = () => {
  return (
    <div>
      <Nav />

      <div className="grid-container">
        <h2>Impressum</h2>
        <div className="impressum">
          <h3>jmmedia GbR</h3>
          <ul>
            <li>
              <p>jmmedia GbR</p>
            </li>
            <li>
              <p>Schwertstraße 1/2</p>
            </li>
            <li>
              <p>71065 Sindelfingen</p>
            </li>
            <li>
              <a href="mailto:info@jmmedia.design">info@jmmedia.design</a>
            </li>
          </ul>
          <ul>
            <li>
              <p>Geschäftsinhaber:</p>
            </li>
            <li>
              <p>Jan Brückner & Max Jung </p>
            </li>
            <li>
              <p>jmmedia GbR </p>
            </li>
            <li>
              <p>Schwertstraße 1/2, 71065 Sindelfingen </p>
            </li>
            <li>
              <p>Steuernr.: 56 175 00050 </p>
            </li>
          </ul>
        </div>
        <div className="impressum">
          <h3>2910visuals</h3>
          <ul>
            <li>
              <p>2910visuals</p>
            </li>
            <li>
              <p>Goldmühlestraße 60</p>
            </li>
            <li>
              <p>71065 Sindelfingen</p>
            </li>
            <li>
              <a href="mailto:michaelweierich@outlook.com">michaelweierich@outlook.com</a>
            </li>
          </ul>
          <ul>
            <li>
              <p>Geschäftsinhaber:</p>
            </li>
            <li>
              <p>Michael Weierich </p>
            </li>
            <li>
              <p>Steuernr.: 56 480 05421 </p>
            </li>
          </ul>
        </div>
        <p>
          © Copyright 2020, jmmedia GbR & 2910visuals, alle Rechte vorbehalten. Jegliche Vervielfältigung oder
          Weiterverbreitung in jedem Medium als Ganzes oder in Teilen bedarf der Schriftlichen Zustimmung der jmmedia
          GbR oder 2910visuals.
        </p>
      </div>
      <Footer />
    </div>
  )
}
export default Impressum
